import React, {useState, useContext } from 'react'
import { graphql } from "gatsby"
import { FORGOT_PASSWORD_REQUEST } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import ContactBackground from '../images/contact-background.png'
import Form from 'components/form'
import { MainContext } from "context/context"
import SEO from 'components/seo'

export const query = graphql`
    query {
        siteFields {
            form(id: "Zm9ybToyMzI4") {
                ...FormFieldsFragment
            }
        }
    }
`

export default ({ data }) => {
    const {
        form,
    } = data.siteFields

    const {
        openModal,
    } = useContext(MainContext)

    const [forgotPasswordRequest] = useMutation(FORGOT_PASSWORD_REQUEST)
    const [error, setError] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = _data => {
        if(!_data['Email address']){
            return handleError(`Please enter an email address.`)
        }

        setIsLoading(true)

        forgotPasswordRequest({
            variables: {
                email: _data['Email address'],
            },
        }).then(result => {
            setIsLoading(false)

            if (result?.data?.forgotPasswordRequest?.success === false) {
                setIsLoading(false)
                handleError(`Those details don't appear to be correct.`)
            } else {
                openModal({
                    type: 'CONTACT_CONFIRM',
                    data: {
                        title: 'Success!',
                        body: `Please check your email for a reset link.`
                    }
                })
            }
        }).catch(error => {
            setIsLoading(false)
            handleError(`Sorry, an error has occurred. Please try again.`)
        })
    }

    const handleError = error => {
        setError(error)

        setTimeout(() => {
            setError(null)
        },2000)
    }

    return (
        <div className="login-page">
            <SEO title="Forgot your password?" />
            <img className="bg" src={ContactBackground} alt="Log in" />
            <div className="container">
                <h1>
                    Forgot your password?
                </h1>
                <div className="wrapper">
                    <Form 
                        form={form}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        error={error}
                        type={'FORGOT_PASSWORD'}
                    />
                </div>
            </div>
        </div>
    )
}